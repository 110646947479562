import { createInstance, TFunction } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next/initReactI18next'
import { getOptions } from './settings'
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}
const initI18next = async (lng: string, ns: string) => {
  const i18nInstance = createInstance()
  await i18nInstance
    .use(initReactI18next)
    .use(resourcesToBackend((lng: string, ns:string) => import(`./locales/${lng}/${ns}.json`)))
    .init(getOptions(lng, ns))
  return i18nInstance
}
export type TTranslationOptions = {
  keyPrefix?: string
}

export async function serverSideTranslation(lng: string, ns: string, options:TTranslationOptions = {}) {
  const i18nextInstance = await initI18next(lng, ns)
  return {
    t: i18nextInstance.getFixedT(lng, ns, options.keyPrefix),
    i18n: i18nextInstance
  }
}

export const fallbackT: TFunction = ((key: string, options?: Record<string, any>) => {
  if (options) {
      let name, value;
      for (name in options) {
          value = options[name]
          if (typeof value === 'string') {
              key = key.replace('{{' + name + '}}', value)
          }
      }
  }
  return key;
}) as TFunction;