'use client'
import { Form } from "react-bootstrap"
import { zodResolver } from '@hookform/resolvers/zod';
import { reactFormErrorToBoolean } from '@general/services/helper';
import Spinner from 'react-bootstrap/Spinner';
import { HTMLAttributes, useId, useState } from "react";
import '@general/styles/components/contact_form.scss'
import "@general/styles/components/rect_tel_input.scss"
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "@general/i18n/client";
import { useSelector } from "react-redux";
import { RootState } from "@general/redux/store";
import { ContactSchema, TContactData } from "@general/services/zod/schemas/zod_schemas";
import { getAxiosInstance } from "@general/services/axios";
import { UNEXPECTED } from "@general/constants/errors";
import { APIErrorMsg } from "@general/components/client_side/common/APIErrorMsg";
import { usePathname } from "next/navigation";
export interface FormProps extends HTMLAttributes<HTMLDivElement> {
    place_from?: string
}
export function ContactForm(formProps: FormProps): JSX.Element {
    let {place_from} = formProps
    if (typeof place_from === 'undefined') {
        place_from = 'undefined_place'
    }
    const [showSpinner, setShowSpinner] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [sending, setSending] = useState(false);
    const [APIErrorCode, setAPIErrorCode] = useState<string | number>(-1);
    const { register, handleSubmit, formState: { errors } } = useForm<TContactData>({ resolver: zodResolver(ContactSchema) })
    const pathname = usePathname()

    const onSubmit: SubmitHandler<TContactData> = async (data) => {
        if (sending) {
            return
        }
        setSending(true);
        setDisplaySuccess(false);
        setAPIErrorCode(-1);
        setShowSpinner(true);
        try {
            const axiosInstance = getAxiosInstance();
            const response = await axiosInstance.post('/api/contact', data);
            if (typeof response.data.success !== 'undefined') {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: 'contactFormSubmit',
                    place_from: place_from,
                    path_from: pathname
                })
                setDisplaySuccess(true);
            } else {
                setAPIErrorCode(parseInt(response.data.errorCode));
            }
        } catch (error) {
            setAPIErrorCode(UNEXPECTED);
        } finally {
            setSending(false);
            setShowSpinner(false);
        }

    }
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'common');
    const nameId = useId();
    const emailId = useId();
    const textareId = useId();
    return <form onSubmit={handleSubmit(onSubmit)} className='contact-form p-5'>
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <Form.Control
                        {...register('name')}
                        type="text"
                        id={nameId}
                        placeholder={t('Your Name')}
                        isInvalid={reactFormErrorToBoolean(errors.name)}
                    />
                    <label htmlFor={nameId}>{t('Your Name')}</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <Form.Control
                        {...register('email')}
                        type="text"
                        id={emailId}
                        placeholder={t('Your Email')}
                        isInvalid={reactFormErrorToBoolean(errors.email)}
                    />
                    <label htmlFor={emailId}>{t('Your Email')}</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="my-3">
                    <Form.Control
                        {...register('message')}
                        as="textarea"
                        id={textareId}
                        placeholder={t('Your message')}
                        rows={3}
                        isInvalid={reactFormErrorToBoolean(errors.message)}
                    />
                    <div className="form-text">{t('Forbidden сhars:') + '<>{}'}</div>
                </div>
            </div>
        </div>
        <div className="text-center mb-3">
            <button className="btn btn-dark btn-lg" type="submit">
                {showSpinner &&
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                }
                {t('Submit')}
            </button>
        </div>
        <div className="message-space">
            {displaySuccess &&
                <div className="text-dark text-center">{t('We will contact you as soon as possible. Thank you for reaching out.')}</div>
            }
            {APIErrorCode != -1 &&
                <div className="text-danger text-center"><APIErrorMsg errorCode={APIErrorCode} /></div>
            }
        </div>

    </form>
}
