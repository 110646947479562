'use client'
import { useTranslation } from "@general/i18n/client";
import { RootState } from "@general/redux/store";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function updateConsent(status: "granted" | "denied"): void {
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("consent", "update", {
            ad_storage: status,
            ad_user_data: status,
            ad_personalization: status,
            analytics_storage: status,
            functionality_storage: status,
            personalization_storage: status,
            security_storage: status
        });
    }
}

export function CookieBanner(): JSX.Element | null {
    const [showBanner, setShowBanner] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);
    const [showToggle, setShowToggle] = useState(false);
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, "common");
    useEffect(() => {
        const consent = localStorage.getItem("cookie_consent");
        if (!consent) {
            setShowBanner(true);
        } else {
            setConsentGiven(consent === "granted");
            setShowToggle(true);
        }
    }, []);

    function acceptCookies(): void {
        updateConsent("granted");
        localStorage.setItem("cookie_consent", "granted");
        setConsentGiven(true);
        setShowBanner(false);
        setShowToggle(true);
    }

    function toggleBanner(): void {
        setShowBanner(!showBanner);
    }

    function revokeConsent(): void {
        updateConsent("denied");
        localStorage.setItem("cookie_consent", "denied");
        setConsentGiven(false);
        setShowBanner(false);
    }

    return (
        <>
            {showBanner && (
                <div className="cookie_banner">
                    <div className="cookie_message_block border p-4 flex justify-between items-center">
                        <p>
                            {consentGiven
                                ? t("You have accepted cookies. If you wish to revoke your consent, click the button below.")
                                : t("We use cookies to improve your experience, including for analytics and personalized content. By clicking 'Accept', you consent to the use of these cookies. You can manage your preferences or withdraw consent at any time.")}
                        </p>
                        <button
                            onClick={consentGiven ? revokeConsent : acceptCookies}
                            className="btn btn-dark px-4 py-2"
                        >
                            {consentGiven ? t("Reject") : t("Accept")}
                        </button>
                        <button
                            onClick={() => {setShowBanner(false); setShowToggle(true);}}
                            className="btn btn-light px-4 py-2 ms-4"
                        >
                            {t("Close")}
                        </button>
                    </div>
                </div>
            )}
            {showToggle && !showBanner && (
                <div className="cookie_banner_toggle" onClick={toggleBanner}>
                    <i className="icon-cookie"></i>
                </div>
            )}
        </>
    );
}