import { OVER_LIMIT } from "@general/constants/errors"
import { useTranslation } from "@general/i18n/client"
import { RootState } from "@general/redux/store"
import { useSelector } from "react-redux"

export type APIErrorCodeProps = {
    errorCode: string | number
}
export function APIErrorMsg({ errorCode }: APIErrorCodeProps) {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'error')
    let errorMsg = ''
    switch (errorCode) {
        case OVER_LIMIT:
            errorMsg = t('Too many requests from you, please try again later.', { ns: 'error' });
            break;
        default:
            errorMsg = t('Unexpected error on request', { ns: 'error' });
            break;
    }
    return <>{errorMsg}</>
}