import { fallbackT } from '@general/i18n';
import { TFunction } from 'i18next';
import * as z from 'zod'
export function isCustomerName(): z.ZodEffects<z.ZodString, string, string> {
    return z.string().min(2).max(128).refine((value: string) => {
        const pattern = new RegExp('^[^0-9!<>,;?=+()@#"°{}_$%:]*$', 'gu');
        return pattern.test(value)
    })
}
export function isPhoneNumber(): z.ZodEffects<z.ZodString, string, string> {
    return z.string().min(12).max(34).refine((value: string) => {
        const pattern = new RegExp('^[+0-9. ()/-]*$', 'gu');
        return pattern.test(value)
    })
}
export function isMessage(): z.ZodEffects<z.ZodString, string, string> {
    return z.string().min(3).max(1000).refine((value: string) => {
        const pattern = new RegExp('[<>{}]', 'gu');
        return !pattern.test(value)
    })
}

export function isGenericName(
    {
        fieldName = '',
        min = 2,
        max = 128,
        t = fallbackT

    }: {
        fieldName?: string
        min?: number
        max?: number
        t?: TFunction
    } = {}
) {
    return z
        .string({
            message: t('{{fieldName}} is an invalid type. Expected a string.', { ns: 'validation', fieldName: fieldName })
        })
        .min(min, {
            message: t('{{fieldName}} must be at least {{min}} characters.', { ns: 'validation', fieldName: fieldName, min: min })
        })
        .max(max, {
            message: t('{{fieldName}} must not exceed {{max}} characters.', { ns: 'validation', fieldName: fieldName, max: max })
        })
        .refine((value: string,) => {
            const pattern = new RegExp(`^[^<>={}]*$`, 'u');
            return pattern.test(value)
        }, {
            message: t('{{fieldName}} contains invalid characters.', { ns: 'validation', fieldName: fieldName })
        })
}